// extracted by mini-css-extract-plugin
export var collectionContainer = "Collection-module--collectionContainer--5757d";
export var collectionDetail = "Collection-module--collectionDetail--2af0d";
export var collectionDetailInner = "Collection-module--collectionDetailInner--a7837";
export var collectionDetailInnerStatement = "Collection-module--collectionDetailInnerStatement--2498c";
export var collectionEthIcon = "Collection-module--collectionEthIcon--c18f3";
export var collectionFeaturedArtists = "Collection-module--collectionFeaturedArtists--b6bdd";
export var collectionFeaturedArtistsCard = "Collection-module--collectionFeaturedArtistsCard--28efd";
export var collectionFeaturedArtistsCardName = "Collection-module--collectionFeaturedArtistsCardName--e2279";
export var collectionFeaturedArtistsInner = "Collection-module--collectionFeaturedArtistsInner--cb16c";
export var collectionHeader = "Collection-module--collectionHeader--7c120";
export var collectionLotsContainer = "Collection-module--collectionLotsContainer--088af";
export var collectionLotsContainerTitle = "Collection-module--collectionLotsContainerTitle--48332";
export var collectionSocialMedia = "Collection-module--collectionSocialMedia--660e7";