import { graphql, PageProps } from "gatsby"
import React, { useMemo } from "react"
import { ContentfulIndividualCollectionPageQuery } from "../../../types/gatsby-graphql"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import { ProfileCard } from "../../components/ProfileCard/ProfileCard"
import SocialMedias from "../../components/SocialMedias/SocialMedias"
import { uniqBy } from "lodash"
import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"
import * as styles from "./Collection.module.scss"
import EthereumIcon from "../../images/EthereumIcon.png"
import Seo from "../../components/Seo/seo"

const Collection = (props: PageProps) => {
  const data: ContentfulIndividualCollectionPageQuery = props.data

  const artists = uniqBy(data?.contentfulSale?.lots, "artist.contentful_id")

  const auctions = useMemo(() => {
    const lots = data?.contentfulSale?.lots?.filter(
      item => item?.auction !== null
    )
    return getAuctionsIds(lots)
  }, [data])

  const LoadableGalleryMonitor = loadable(
    () => import("../../components/GalleryMonitor/GalleryMonitor")
  )

  return (
    <div className={styles.collectionContainer}>
      <Seo title={data?.contentfulSale?.name || ""} />
      <div className={styles.collectionHeader}>
        <img
          src={data?.contentfulSale?.mainMedia?.file?.url!}
          alt={data?.contentfulSale?.mainMedia?.title!}
        />
        <h1>{data?.contentfulSale?.name}</h1>
      </div>
      <div className={styles.collectionDetail}>
        <div className={styles.collectionDetailInner}>
          <div className={styles.collectionEthIcon}>
            <h1>Available on</h1>
            <img src={EthereumIcon} alt="Ethereum Icon" />
          </div>
          <h3>{`Curated by: ${[
            data?.contentfulSale?.curator?.firstName,
            data?.contentfulSale?.curator?.middleName,
            data?.contentfulSale?.curator?.lastName,
          ]
            .join(" ")
            .trim()}`}</h3>
          <SocialMedias classname={styles.collectionSocialMedia} />
          <h3>About Curator</h3>
          <span>{data?.contentfulSale?.curator?.description?.description}</span>
        </div>
        <div className={styles.collectionDetailInnerStatement}>
          <h1>Curatorial Statement</h1>
          <span>
            {data?.contentfulSale?.curatorialStatement?.curatorialStatement}
          </span>
        </div>
      </div>
      <div className={styles.collectionFeaturedArtists}>
        <h2>Featured Artists</h2>
        <div className={styles.collectionFeaturedArtistsInner}>
          {artists.map(lots => {
            if (!lots?.artist) {
              return null
            }

            return (
              <ProfileCard
                name={
                  [lots?.artist?.firstName, lots?.artist?.lastName]
                    .join(" ")
                    .trim()!
                }
                avatar={lots?.artist?.profilePicture?.gatsbyImageData}
                account={lots?.artist?.username!}
                url={`/artist/${lots.artist.contentful_id}`}
              />
            )
          })}
        </div>
      </div>
      <LoadableGalleryMonitor
        auctions={auctions.filter(String)}
        render={({ result }: any) => {
          const enrichedData = enricheLotWithAuction(
            data?.contentfulSale?.lots || [],
            result?.auctions
          )

          return (
            <>
              <h1 className={styles.collectionLotsContainerTitle}>
                All Artworks
              </h1>
              <div className={styles.collectionLotsContainer}>
                {enrichedData?.map((mapData: any) => {
                  if (!mapData?.lot?.auction) {
                    return null
                  }

                  const mimeType =
                    mapData?.lot?.auction?.mint?.mediaAsset?.media?.mimeType! ||
                    ""

                  let url = ""

                  if (mimeType.indexOf("video") === -1) {
                    url =
                      mapData?.lot?.auction?.mint?.mediaAsset?.media
                        ?.gatsbyImageData?.images?.fallback?.src ||
                      mapData?.lot?.auction?.mint?.mediaAsset?.media?.file?.url!
                  } else {
                    url =
                      mapData?.lot?.auction?.mint?.mediaAsset?.configuration
                        ?.mp4Url ||
                      mapData?.lot?.auction?.mint?.mediaAsset?.media?.file?.url!
                  }

                  return (
                    <NFTCard
                      title={mapData?.lot?.auction?.mint?.name!}
                      assetType={mimeType}
                      imgUrl={url}
                      price={mapData?.auction?.reservePriceInETH!}
                      by={[
                        mapData?.lot?.artist?.firstName,
                        mapData?.lot?.artist?.lastName,
                      ]
                        .join(" ")
                        .trim()}
                      linkTo={`/artwork/${mapData?.lot?.contentful_id}`}
                    />
                  )
                })}
              </div>
            </>
          )
        }}
      />
    </div>
  )
}

export default Collection

export const CollectionQuery = graphql`
  query ContentfulIndividualCollectionPage($slug: String!) {
    contentfulSale(slug: { eq: $slug }) {
      startDate
      slug
      name
      description {
        description
      }
      endDate
      mainMedia {
        title
        file {
          url
        }
      }
      curatorialStatement {
        curatorialStatement
      }
      lots {
        contentful_id
        lotId
        auction {
          ...lotAuctionFragment
        }
        artist {
          website
          walletAddress
          username
          twitterUrl
          spaceId
          referenceLinks {
            referenceLinks
          }
          middleName
          lastName
          instagramUrl
          firstName
          facebookUrl
          email
          contactNumber
          location
          contentful_id
          artDescription {
            artDescription
          }
          profilePicture {
            gatsbyImageData

            file {
              url
            }
          }
          coverImage {
            file {
              url
            }
          }
        }
      }
      curator {
        firstName
        middleName
        lastName
        description {
          description
        }
        username
        walletAddress
        twitterUrl
        instagramUrl
        facebookUrl
        website
      }
    }
  }
`
